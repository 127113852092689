<template>
  <v-row justify="center" align="center">
    <v-col class="d-flex flex-column align-center" cols="12" sm="8" md="6">
      <h3 class="header-margin">Zaloguj się do grecko-polskiego translatora</h3>
      <div id="firebaseui-auth-container"></div>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "@/firebaseinit";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

let redirectUrl = "/translate";
if (window.location.search) {
  redirectUrl += window.location.search;
}

const ui = new firebaseui.auth.AuthUI(firebase.auth());
const uiConfig = {
  signInSuccessUrl: redirectUrl,
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID]
};

export default {
  mounted() {
    ui.start("#firebaseui-auth-container", uiConfig);
  }
};
</script>

<style scoped>
.header-margin {
  margin: 50px 0 50px 0;
}
</style>
